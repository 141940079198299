import {
  Dropdown,
  Input,
  InputRef,
  Popconfirm,
  Space,
  Tag,
  Typography,
  MenuProps,
} from "antd";
import { MoreOutlined, DownOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import {
  EditPenIcon,
  DuplicateIcon,
  DeleteIcon,
} from "../../icons-and-animations/Icons";
import "./SideBar.scss";
import classNames from "classnames";
const MenuTagTab = ({
  vp,
  index,
  selectedVideoPartName,
  deleteVideoPart,
  renameVideoPart,
  duplicateVideoPart,
  handleClick,
  allVideoPartsNames,
  selectedIndex,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: vp.name });

  // State definitions
  const [isEditing, setIsEditing] = useState(false);
  const [newName, setNewName] = useState(vp.name);
  const [originalName, setOriginalName] = useState(vp.name);
  const [error, setError] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [showPopConfirm, setShowPopConfirm] = useState(false);
  const [canDrag, setCanDrag] = useState(true);
  const inputRef = useRef<InputRef | any>(null);

  // Function definitions
  const handleMenuClick = ({ key }) => {
    switch (key) {
      case "rename":
        setIsEditing(true);
        setOriginalName(newName); // Save the original name before editing
        setError("");
        break;
      case "duplicate":
        duplicateVideoPart(vp.name);
        break;
      case "delete":
        setShowPopConfirm(true);
        break;
      default:
        break;
    }
  };

  const handleConfirmDelete = () => {
    deleteVideoPart(vp.name);
    setShowPopConfirm(false);
  };

  const handleCancelDelete = () => {
    setShowPopConfirm(false);
  };

  const handleRenameBlur = () => {
    if (newName !== vp.name && allVideoPartsNames.includes(newName)) {
      setError("This name already exists.");
    } else {
      setIsEditing(false);
      if (newName !== vp.name) {
        renameVideoPart(vp.name, newName);
      }
    }
  };

  const handleRenameChange = (e) => {
    setNewName(e.target.value);
    if (
      e.target.value !== vp.name &&
      allVideoPartsNames.includes(e.target.value)
    ) {
      setError("This name already exists.");
    } else {
      setError("");
    }
  };

  const handleRenameKeyPress = (e) => {
    if (e.key === "Enter") {
      handleRenameBlur();
    } else if (e.key === "Escape") {
      setNewName(originalName); // Reset to the original name
      setIsEditing(false);
      setError(""); // Clear any errors
    }
  };

  // Effect definitions
  useEffect(() => {
    if (isEditing) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  // Extra
  const commonStyle = {
    transition: "unset",
  };

  useEffect(() => {
    if (isEditing) {
      setCanDrag(false);
    } else {
      setCanDrag(true);
    }
  }, [isEditing]);

  const style =
    transform && canDrag
      ? {
          ...commonStyle,
          transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
          transition: isDragging ? "unset" : transition,
          opacity: isDragging ? 0.1 : 1,
        }
      : commonStyle;

  const items: MenuProps["items"] = [
    {
      label: "Rename scene",
      key: "rename",
      icon: (
        <div className="icon-margin">
          <EditPenIcon />
        </div>
      ),
    },
    {
      label: "Duplicate scene",
      key: "duplicate",
      icon: (
        <div className="icon-margin">
          <DuplicateIcon />
        </div>
      ),
    },
    {
      label: "Delete scene",
      key: "delete",
      icon: (
        <div className="icon-margin">
          <DeleteIcon />
        </div>
      ),
    },
  ];

  const menuProps: MenuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div
      className="video-part-container"
      key={vp.name}
      ref={setNodeRef}
      style={style}
      {...(canDrag ? attributes : {})}
      {...(canDrag ? listeners : {})}
    >
      <Tag
        onClick={(event) => {
          if (!menuOpen && !showPopConfirm) handleClick(event);
        }}
        id={"video-part-" + index}
        className={classNames(
          "video-part-tag",
          selectedVideoPartName === vp.name ? "selected" : ""
        )}
        key={vp.name}
      >
        <Space direction="vertical" style={{ position: "relative" }}>
          <Space className="scene-title-container">
            {isEditing ? (
              <Popconfirm
                icon={false}
                onCancel={() => {
                  setNewName(originalName);
                  setIsEditing(false);
                  setError("");
                }}
                onConfirm={handleRenameBlur}
                title={
                  <div style={{ maxWidth: "250px" }}>
                    <Input
                      ref={inputRef}
                      value={newName}
                      onChange={handleRenameChange}
                      onBlur={handleRenameBlur}
                      onKeyDown={handleRenameKeyPress}
                      size="small"
                      status={error ? "error" : ""}
                    />
                    {error && (
                      <Typography.Text
                        type="danger"
                        style={{ color: "#d9d9d9" }}
                      >
                        {error}
                      </Typography.Text>
                    )}
                  </div>
                }
                open={true}
              ></Popconfirm>
            ) : (
              ""
            )}
            <p
              style={{ opacity: isEditing ? 0.5 : 1 }}
              className="scene-title"
              title={vp.name}
            >
              {vp.name}
            </p>
            <Dropdown
              menu={menuProps}
              overlayClassName="dropdown-menu"
              trigger={["click"]}
              onOpenChange={(visible) => setMenuOpen(visible)}
            >
              <button
                type="button"
                className={"dropdown-button"}
                onClick={(e) => e.stopPropagation()}
              >
                <span className={`icon-transition ${menuOpen ? "rotate" : ""}`}>
                  {menuOpen ? (
                    <DownOutlined className="icon-centered" />
                  ) : (
                    <MoreOutlined className="icon-centered" />
                  )}
                </span>
              </button>
            </Dropdown>
          </Space>
        </Space>
        {index !== selectedIndex && index + 1 !== selectedIndex && (
          <div className="border-tag"></div>
        )}
        <Popconfirm
          title="Are you sure to delete this part?"
          open={showPopConfirm}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          okText="Yes"
          cancelText="No"
        />
      </Tag>
    </div>
  );
};

export default MenuTagTab;
