import { Layout, Space } from "antd";
import "./SideBar.scss";
import { HorizontalDivider } from "../../BaseComps/HorizontalDivider";
import { NewModButtonsList } from "../mods/NewModButtons/NewModButtonsList";
import { ModsList } from "../mods/ModsList";
import { SideFormActionButtons } from "../../SideFormActionButtons";

const { Sider } = Layout;
type Props = {};

export function SideBar(props: Props) {
  return (
    <Sider
      breakpoint="lg"
      width={"26rem"}
      collapsedWidth={"0"}
      className={"playground-left-sidebar"}
    >
      <Space
        className={"playground-left-sidebar-inner-container"}
        direction={"vertical"}
        split={<HorizontalDivider />}
      >
        <NewModButtonsList />
        <ModsList />
        <SideFormActionButtons />
      </Space>
    </Sider>
  );
}
