import { MSTContext } from "../../stores/main";
import { Select, Space } from "antd";
import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { useHistory } from "react-router-dom";

type Props = {};
type IOption = {
  value: string;
  label: string;
  updatedAt: string;
  key?: string;
};

export const ConnectToProject = observer((props: Props) => {
  const {
    platformStore: {
      allProjects: allProjectsFromStore,
      selectedProjectId,
      isLoadingProjectList,
    },
    currentTab,
  } = useContext(MSTContext);
  const history = useHistory();
  const userHasMultipleAccounts = allProjectsFromStore.some(
    (p) => p.projectAccountId !== allProjectsFromStore[0].projectAccountId
  );
  const pjOptions: IOption[] = allProjectsFromStore.map((p) => ({
    label:
      p.title + (userHasMultipleAccounts ? ` | ${p.projectAccountId}` : ""),
    value: p.id,
    updatedAt: p.updatedAt,
  }));

  const { all: allProjects, recent: recentProjects } = pjOptions.reduce<{
    recent: IOption[];
    all: IOption[];
  }>(
    (acc, project, index) => {
      const projectDate = new Date(project.updatedAt);
      if (acc.recent.length < 5) {
        acc.recent.push({
          ...project,
          key: project.label + project.updatedAt + index.toString(),
        });
        acc.recent.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      } else if (projectDate > new Date(acc.recent[4].updatedAt)) {
        const removed = acc.recent.pop();
        if (removed) acc.all.push(removed);
        acc.recent.push({
          ...project,
          key: project.label + project.updatedAt + index.toString(),
        });
        acc.recent.sort(
          (a, b) =>
            new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
        );
      } else {
        acc.all.push({
          ...project,
          key: project.label + project.updatedAt + index.toString(),
        });
      }
      return acc;
    },
    { recent: [], all: [] }
  );
  allProjects.sort(function (a, b) {
    const aName = a.label ? a.label : a.value;
    const bName = b.label ? b.label : b.value;
    if (aName.toLocaleLowerCase() < bName.toLocaleLowerCase()) {
      return -1;
    } else {
      return 1;
    }
  });
  return (
    <>
      <Space
        className="ConnectToProject"
        style={selectedProjectId === null ? { display: "none" } : undefined}
      >
        <Select<string>
          id="project-list-selector"
          placeholder={
            isLoadingProjectList ? "Loading projects" : "Select a project"
          }
          value={selectedProjectId || undefined}
          options={[
            {
              label: "Recent projects",
              value: "recent",
              options: recentProjects,
            },
            {
              label: "All projects",
              value: "all",
              options: allProjects,
            },
          ]}
          onChange={(v) => history.push(`/${v}/${currentTab}/`)}
          showSearch
          loading={isLoadingProjectList}
          style={{
            minWidth: "13rem",
            border: "1px solid #424242",
            borderRadius: 6,
          }}
          filterOption={(input, option) => {
            return (
              (option as IOption)?.label
                .toLowerCase()
                .indexOf(input.toLowerCase()) > -1 ||
              (option as IOption)?.value
                .toLowerCase()
                .indexOf(input.toLowerCase()) > -1
            );
          }}
          disabled={isLoadingProjectList}
        />
        <br />
      </Space>
    </>
  );
});
