import { NoProjectSelectedScreen } from "./NoProjectSelectedScreen";
import { Layout } from "antd";
import { SideBar } from "./playground-left-sidebar/SideBar";
import { VidContainer } from "./MainVidPlayer/VidContainer";
import { SchemaEditors } from "./RightPanel/SchemaEditors";
import { useContext, useCallback } from "react";
import { MSTContext } from "../stores/main";
import { observer } from "mobx-react-lite";
import FlowPage from "../pages/flow/FlowPage";
import GroupButtons from "./GroupButtons/GroupButtons";
import { tabsOptions } from "../stores/consts";
import { useNavigationPrompt } from "../utils/navigateWithPrompt";
import "./MainLayout.scss";
import { VideoPartsSelectionTabs } from "./playground-left-sidebar/VideoPartsSelectionTabs";

type Props = {};
const { Content } = Layout;

export const MainLayout = observer((props: Props) => {
  const {
    platformStore: {
      selectedProjectId,
      allProjects,
      initialProjectLoadSuccess,
    },
    accountStore,
    setCurrentTab,
    currentTab,
  } = useContext(MSTContext);
  const selectedProjectExists = allProjects.find(
    (project) => project.id === selectedProjectId
  );

  if (selectedProjectId && !currentTab) {
    setCurrentTab("layers");
  }

  const navigateWithPrompt = useNavigationPrompt();
  const onTabChange = useCallback(
    async (tab: any) => {
      const route = `/${selectedProjectId}/${tab}/`;
      await navigateWithPrompt(route, () => setCurrentTab(tab));
      return false;
    },
    [setCurrentTab, selectedProjectId, navigateWithPrompt]
  );
  const hasTabs = accountStore.isBlingsAccount || false;

  const getCurrentView = () => {
    switch (currentTab) {
      case "layers":
        return (
          <>
            <Content>
              <div className="layers-content">
                <div className="scenes-tabs-wrapper">
                  <VideoPartsSelectionTabs />
                </div>
                <div className="layers-main-wrapper">
                  <SideBar />
                  <VidContainer />
                  <SchemaEditors />
                </div>
              </div>
            </Content>
          </>
        );
      case "flow":
        return (
          <Content className="flow-content">
            <FlowPage />
          </Content>
        );
      case "thumb":
        return <SchemaEditors />;
      default:
        return (
          <>
            <SideBar />
            <Content>
              <VidContainer />
              <SchemaEditors />
            </Content>
          </>
        );
    }
  };

  return !selectedProjectId ? (
    <NoProjectSelectedScreen
      hasProjects={!initialProjectLoadSuccess || !!allProjects.length}
      projectExists={true}
    />
  ) : selectedProjectExists !== undefined ? (
    <div className="main-layout-wrapper">
      {selectedProjectId && hasTabs ? (
        <aside className="nav-side-bar">
          <GroupButtons
            isLoading={false}
            items={tabsOptions}
            initialActive={currentTab}
            onChange={onTabChange}
          />
        </aside>
      ) : null}
      <Layout className={"main"} style={{ background: "#1a1a1a" }}>
        {getCurrentView()}
      </Layout>
    </div>
  ) : (
    <NoProjectSelectedScreen
      hasProjects={!initialProjectLoadSuccess || !!allProjects.length}
      projectExists={selectedProjectExists !== undefined}
    />
  );
});
