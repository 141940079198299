import { useState } from "react";
import "./GroupButtons.scss";
import {
  LayersIcon,
  FlowIcon,
  ImageIcon,
} from "../../icons-and-animations/Icons";

const iconMap = {
  LayersIcon: LayersIcon,
  FlowIcon: FlowIcon,
  ImageIcon: ImageIcon,
};

const GroupButtons = ({ items, initialActive, onChange, isLoading }) => {
  const [activeButton, setActiveButton] = useState(
    initialActive || items[0]?.value
  );
  const handleClick = (value) => {
    if (onChange(value)) {
      setActiveButton(value);
    }
  };

  const buttons = items.map((button) => {
    const IconComponent = iconMap[button.icon];
    return (
      <div className="btn-wrapper" key={button.path}>
        <button
          key={button.value}
          value={button.path}
          className={`btn  ${button.value}
            ${
              initialActive === button.value
                ? " btn-selected"
                : " btn-unselected"
            }`}
          onClick={() => handleClick(button.path)}
        >
          {IconComponent && <IconComponent className={button.value} />}
        </button>
        <span className="btn-title">{button.title}</span>
      </div>
    );
  });

  return (
    <div className="group-button-container">
      {isLoading ? <div className="loading"></div> : buttons}
    </div>
  );
};

export default GroupButtons;
