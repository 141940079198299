import { Modal, Tag } from "antd";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState, useRef, useCallback } from "react";
import { MSTContext } from "../../stores/main";
import { useHistory } from "react-router-dom";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  closestCenter,
  MeasuringStrategy,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
} from "@dnd-kit/sortable";
import { BlingsBtn } from "../antd-extensions/blings-btn.component";
import { AsyncOpState } from "../../stores/async-op-state";
import { VidPart } from "../../API";
import MenuTagTab from "./MenuTagTab";
import "./VideoPartsSelectionTabs.scss";
import {
  restrictToWindowEdges,
  restrictToHorizontalAxis,
} from "@dnd-kit/modifiers";
import {
  LeftScrollArrowIcon,
  RightScrollArrowIcon,
} from "../../icons-and-animations/Icons";
import classNames from "classnames";

type Props = {};

export const VideoPartsSelectionTabs = observer((props: Props) => {
  const {
    platformStore: {
      projectWorkspaceVersion,
      setVideoParts,
      selectedProjectId,
      selectedVideoPartName,
      deleteVideoPart,
      renameVideoPart,
      duplicateScene,
    },
    modsStore: { hasUnsavedChanges },
  } = useContext(MSTContext);
  const [modalUnsavedChanges, setModalUnsavedChanges] = useState("");
  const [allVideoPartsNames, setAllVideoPartsNames] = useState<string[]>([]);
  const [selectedVideoPartIndex, setSelectedVideoPartIndex] = useState(-1);
  const history = useHistory();
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollAmount = 300; // Pixels to scroll
  const [activeId, setActiveId] = useState(null);
  const [scrolling, setScrolling] = useState({
    isScoll: false,
    hasScrollLeft: false,
    hasScrollRight: false,
  });

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    // Check if 'over' is not null before accessing its properties
    if (over && active.id !== over.id) {
      // Add your rearrange logic here
      const items = Array.from(
        projectWorkspaceVersion?.videoParts || []
      ) as Array<VidPart>;
      const activeItemIndex = items.findIndex(
        (item) => item.name === active.id
      );
      const overItemIndex = items.findIndex((item) => item.name === over.id);
      const [reorderedItem] = items.splice(activeItemIndex, 1);
      items.splice(overItemIndex, 0, reorderedItem);
      setVideoParts(items);
    }
  };

  const scrollButtons = useCallback(() => {
    if (!scrollRef.current || !projectWorkspaceVersion?.videoParts) {
      return {
        isScoll: false,
        hasScrollLeft: false,
        hasScrollRight: false,
      };
    }
    return {
      isScoll: scrollRef.current.scrollWidth > scrollRef.current.clientWidth,
      hasScrollLeft: scrollRef?.current?.scrollLeft > 0,
      hasScrollRight:
        scrollRef?.current?.scrollLeft <
        scrollRef?.current?.scrollWidth - scrollRef.current?.clientWidth,
    };
  }, [scrollRef, projectWorkspaceVersion?.videoParts]);

  useEffect(() => {
    const selectedIndex = projectWorkspaceVersion?.videoParts
      ? projectWorkspaceVersion.videoParts.findIndex(
          (vp) => vp.name === selectedVideoPartName
        )
      : -1;
    setSelectedVideoPartIndex(selectedIndex);

    setAllVideoPartsNames(
      projectWorkspaceVersion?.videoParts?.map((vp) => vp.name) || []
    );
  }, [selectedVideoPartName, projectWorkspaceVersion?.videoParts]);

  const scrollToMiddle = (event) => {
    const item = event.currentTarget; // The clicked item
    if (scrollRef.current && item) {
      const container = scrollRef.current;

      // Calculate item's center relative to the container
      const containerCenter = container.offsetWidth / 2;
      const itemOffset = item.offsetLeft + item.offsetWidth / 2;

      // Scroll the container to align the item in the center
      const scrollPosition = itemOffset - containerCenter;
      container.scrollTo({
        left: scrollPosition,
        behavior: "smooth",
      });
    }
  };

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -scrollAmount, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };

  function handleDragStart(event) {
    setActiveId(event.active.id);
  }

  useEffect(() => {
    const handleResize = () => setScrolling(scrollButtons());
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [scrollRef, scrollButtons, projectWorkspaceVersion?.videoParts]);

  const handleScroll = useCallback(() => {
    setScrolling(scrollButtons());
  }, [scrollButtons]);

  return (
    <div className="scroll-container">
      {scrolling.isScoll ? (
        <div className="scroll-btn-wrapper">
          <div
            onClick={scrollLeft}
            className={classNames(
              "scroll-arrow left",
              scrolling.hasScrollLeft ? " shadow" : "disabled"
            )}
          >
            <LeftScrollArrowIcon />
          </div>
        </div>
      ) : null}
      <DndContext
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        modifiers={[restrictToWindowEdges, restrictToHorizontalAxis]}
        collisionDetection={closestCenter}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
        sensors={sensors}
      >
        <div
          className="vid-part-tags-container"
          ref={scrollRef}
          onScroll={handleScroll}
          style={{ marginLeft: scrolling.isScoll ? "0" : "36px" }}
        >
          <SortableContext
            items={
              projectWorkspaceVersion?.videoParts?.map((vp) => vp.name) || []
            }
            strategy={horizontalListSortingStrategy}
          >
            {projectWorkspaceVersion?.videoParts &&
              projectWorkspaceVersion.videoParts.map((vp, index) => (
                <MenuTagTab
                  vp={vp}
                  index={index}
                  key={vp.name}
                  selectedIndex={selectedVideoPartIndex}
                  selectedVideoPartName={selectedVideoPartName}
                  deleteVideoPart={deleteVideoPart}
                  handleClick={(event) => {
                    if (hasUnsavedChanges) {
                      setModalUnsavedChanges(vp.name);
                    } else {
                      history.push(`/${selectedProjectId}/layers/${vp.name}`);
                      scrollToMiddle(event);
                    }
                  }}
                  renameVideoPart={renameVideoPart}
                  duplicateVideoPart={duplicateScene}
                  allVideoPartsNames={allVideoPartsNames}
                />
              ))}
            <DragOverlay modifiers={[restrictToWindowEdges]}>
              {activeId ? (
                <Tag
                  className="video-part-tag"
                  key={activeId}
                  style={{ padding: "0 10px" }}
                >
                  {activeId}
                </Tag>
              ) : null}
            </DragOverlay>
          </SortableContext>
        </div>
        {scrolling.isScoll ? (
          <div className="scroll-btn-wrapper">
            <div
              onClick={scrollRight}
              className={classNames(
                "scroll-arrow right",
                scrolling.hasScrollRight ? " shadow" : "disabled"
              )}
            >
              <RightScrollArrowIcon />
            </div>
          </div>
        ) : null}
      </DndContext>

      <Modal
        open={!!modalUnsavedChanges}
        onCancel={() => {
          setModalUnsavedChanges("");
        }}
        closable={false}
        footer={[
          <div
            style={{
              display: "flex",
              gap: "15px",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <button
              key="ok"
              type="button"
              onClick={() => {
                history.push(
                  `/${selectedProjectId}/layers/${modalUnsavedChanges}`
                );
                setModalUnsavedChanges("");
              }}
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                background: "none",
                border: "none",
              }}
            >
              Proceed without saving
            </button>
            <BlingsBtn
              key="publish"
              className={"publish"}
              opState={AsyncOpState.Changed}
              htmlType={"submit"}
              btnTexts={{
                [AsyncOpState.Changed]: "Cancel",
              }}
              onClick={() => setModalUnsavedChanges("")}
            />
          </div>,
        ]}
      >
        <div className="popup-text">
          You have pending changes in your connectors. Leaving this page will
          discard these changes
        </div>
      </Modal>
    </div>
  );
});
