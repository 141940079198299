import { types, Instance } from "mobx-state-tree";
import {
  fetchAuthSession,
  fetchUserAttributes,
  fetchMFAPreference,
  FetchMFAPreferenceOutput,
} from "aws-amplify/auth";
import { generateClient } from "aws-amplify/api";
import { getAccount } from "../graphql/queries";
import { GetAccountQuery } from "../API";
import {
  AccountProps,
  AccountSettingsProps,
  FileUploadProps,
} from "./platformSchema";
import { UserAccountMFA } from "../types/enum/UserAccountMFA";
import { AuthSession } from "../types/awsTypes";

// Graphql Client
const client = generateClient();

const accountDataModel = types.model({
  // id: types.string,
  // name: types.string,
  ...AccountProps,
  // phone: types.maybeNull(types.string)
});
export type IAccountDataModel = Instance<typeof accountDataModel>;

const accountSettingsModel = types.model({
  ...AccountSettingsProps,
});
export type IAccountSettingsModel = Instance<typeof accountSettingsModel>;

const userAttributesModel = types.model({
  sub: types.string,
  identityId: types.string,
  email: types.string,
  phone_number: types.maybeNull(types.string),
  "custom:mfaConfig": types.maybeNull(types.string),
});
export type IUserAttributesModel = Instance<typeof userAttributesModel>;

const fileUploadModel = types.model({
  ...FileUploadProps,
  // originalName: types.string,
  createdAt: types.string,
  fileName: types.string,
  // fileStatus: types.string,
  // fileError: types.maybeNull(types.string),
  // numRecords: types.maybeNull(types.number),
  // numErrors: types.maybeNull(types.number),
});
export type IFileUploadModel = Instance<typeof fileUploadModel>;
export type IFileUploadsModel = Instance<typeof fileUploadModel>[];
const fontModel = types.model({
  fontFamily: types.string,
  variants: types.array(
    types.model({
      name: types.string,
      weight: types.number,
      style: types.string,
    })
  ),
});
export type IFontModel = Instance<typeof fontModel>;
export type IFontsModel = Instance<typeof fontModel>[];

// Model for a single account's fonts
const accountFontsModel = types.model({
  id: types.identifier, // The 'id' field will serve as a unique identifier for each account
  fonts: types.array(fontModel),
});

export const accountModel = types
  .model("accountStore", {
    cognitoGroupId: types.maybe(types.string),
    cognitoExtraGroupIds: types.optional(types.array(types.string), []),
    accountData: types.maybe(accountDataModel),
    mfaEnabled: types.maybe(types.boolean),
    /**
     * This is the MFA configuration from the organization account settings (The account on dynamo DB)
     */
    organizationRequireMFA: types.maybeNull(types.boolean),
    /**
     * This is the MFA configuration from the user account settings (The account on cognito)
     * 0 - Unset - Follow the organization settings
     * 1 - Disabled - MFA is not required
     * 2 - Enabled - MFA is required
     */
    personalAccountMFARequired: types.maybeNull(types.number),
    mfaPreferences: types.maybeNull(types.string),
    mfaEnabledArray: types.optional(types.array(types.string), []),
    userAttributes: types.maybe(userAttributesModel),
    fileUploads: types.optional(types.array(fileUploadModel), []),
    hubspotConnection: types.maybeNull(types.string),
    // fonts: types.optional(types.array(fontModel), []),
    fontsPerAccountCollectionModel: types.optional(
      types.map(accountFontsModel),
      {}
    ),
    loadStatus: types.optional(
      types.enumeration("LoadStatus", ["unloaded", "loading", "loaded"]),
      "unloaded"
    ),
  })
  .volatile((self) => ({
    loadCallbackFunctions: [] as (() => void)[],
  }))
  .views((self) => ({
    get isAdmin() {
      return self.cognitoGroupId === "blingsadmin";
    },
    get isBlingsAccount() {
      return (
        self.cognitoGroupId === "blings_account" ||
        self.cognitoExtraGroupIds?.includes("blings_account")
      );
    },
    get hubspotCredentialsAreValid() {
      if (!self.hubspotConnection) return false;
      const cred = JSON.parse(self.hubspotConnection);
      if (Date.now() >= cred.updatedAt + cred.expiresIn * 1000) {
        return false;
      }
      return true;
    },
    get mainGroupId() {
      return self.cognitoGroupId;
    },
    get extraGroupIds() {
      return self.cognitoExtraGroupIds;
    },
    get allGroupIds() {
      return [self.cognitoGroupId, ...self.cognitoExtraGroupIds];
    },

    /**
     * Get the MFA configuration for the user. Personal MFA configuration takes precedence over organization MFA configuration
     */
    get MFARequired() {
      if (self.personalAccountMFARequired === UserAccountMFA.UNSET) {
        return self.organizationRequireMFA;
      }
      return self.personalAccountMFARequired === UserAccountMFA.ENABLED;
    },
    get MFAEnabled() {
      if (self.mfaEnabledArray.length !== 0) {
        return true;
      }
      return false;
    },
    get UserMFAPreference() {
      return self.mfaPreferences;
    },
  }))
  .actions((self) => ({
    addFunctionToLoadCallback(func: () => void) {
      if (self.loadStatus !== "loaded") self.loadCallbackFunctions.push(func);
      else func();
    },
    addFontsPerAccount(accountId: string, fonts: IFontsModel) {
      self.fontsPerAccountCollectionModel.put({
        id: accountId,
        fonts,
      });
    },
    getFontsForAccount(accountId: string) {
      return self.fontsPerAccountCollectionModel.get(accountId)?.fonts;
    },
    setGroupId(cognitoGroupId: string) {
      self.cognitoGroupId = cognitoGroupId;
    },
    setExtraGroupIds(cognitoExtraGroupIds: string[]) {
      self.cognitoExtraGroupIds.replace(cognitoExtraGroupIds);
    },
    setUserAttributes(attr: IUserAttributesModel) {
      // const {email} = attr
      self.userAttributes = attr;
    },
    setAccountData(accountData: IAccountDataModel) {
      self.accountData = accountData;
    },
    setMfaEnabled(mfaEnabled: boolean) {
      self.mfaEnabled = mfaEnabled;
    },
    setFileUploads(fileUploads: any) {
      self.fileUploads.replace(fileUploads);
    },
    setOrganizationRequireMFA(enforcedMFA: boolean) {
      self.organizationRequireMFA = enforcedMFA;
    },
    setPersonalAccountMFARequired(mfaConfig: number) {
      self.personalAccountMFARequired = mfaConfig;
    },
    setMFAPreferences(mfaPreferences: string) {
      self.mfaPreferences = mfaPreferences;
    },
    setMFAEnabledArray(mfaEnabledArray: string[]) {
      self.mfaEnabledArray.replace(mfaEnabledArray);
    },

    // setFonts(fonts: IFontsModel) {
    //   self.fonts.replace(fonts);
    // },
    setFontsPerAccount(data: { [key: string]: IFontsModel }) {
      self.fontsPerAccountCollectionModel.clear();
      Object.keys(data).forEach((key) => {
        self.fontsPerAccountCollectionModel.put({
          id: key,
          fonts: data[key],
        });
      });
    },
    setLoadStatus(status: "loading" | "loaded") {
      self.loadStatus = status;
    },
  }))
  .actions((self) => ({
    async loadAccountData() {
      self.setLoadStatus("loading");
      try {
        const promises = [
          fetchAuthSession(),
          fetchUserAttributes(),
          fetchMFAPreference(),
        ];
        const authResult = await Promise.all(promises);
        const fullAuth = authResult[0] as AuthSession;
        const attributes = authResult[1] as IUserAttributesModel;
        const {
          preferred,
          enabled,
        } = authResult[2] as FetchMFAPreferenceOutput;
        const session = fullAuth.tokens;

        if (!session) throw new Error("No session");
        if (!session.idToken) throw new Error("No idToken");
        if (!attributes.email) throw new Error("No email");
        // Not required for now
        //if(!attributes.phone_number) throw new Error("No phone_number");
        const groups = session.idToken.payload["cognito:groups"] as string[];
        const attr: IUserAttributesModel = {
          sub: attributes.sub as string,
          email: attributes.email,
          phone_number: attributes.phone_number || null,
          identityId: fullAuth.identityId as string,
          "custom:mfaConfig": attributes["custom:mfaConfig"] || null,
        };
        if (groups.length > 1) {
          console.log("more than one group!");
        }
        self.setGroupId(groups[0]);
        self.setExtraGroupIds(groups.slice(1));
        self.setUserAttributes(attr);
        self.setPersonalAccountMFARequired(
          attr["custom:mfaConfig"]
            ? parseInt(attr["custom:mfaConfig"])
            : UserAccountMFA.UNSET
        );
        self.setMFAEnabledArray(enabled || []);
        if (preferred) {
          self.setMFAPreferences(preferred);
        }

        // Query for fonts on every group that this user belongs to
        const accountPromises: any[] = [];
        for (const group of groups) {
          accountPromises.push(
            client.graphql({
              query: getAccount,
              variables: { id: group },
            })
          );
        }
        const result: { [key: string]: any } = {};
        const accountResp = (await Promise.all(accountPromises)) as {
          data: GetAccountQuery;
        }[];
        // Load account data from the first group
        if (accountResp[0].data.getAccount) {
          const {
            projects,
            fileuploads,
            fonts,
            ...accountSettings
          } = accountResp[0].data.getAccount;
          self.setFileUploads(fileuploads || []);
          self.setAccountData({
            ...accountSettings,
          });
        }
        accountResp.forEach((resp, i) => {
          const group = groups[i] as string;
          const { ...accountSettings } = resp.data.getAccount;
          if (resp.data.getAccount) {
            const { fonts } = resp.data.getAccount;
            result[group] = fonts || [];
          }
          if (accountSettings.mfaEnabled) {
            self.setMfaEnabled(true);
            if (accountSettings.mfaEnabled) {
              self.setOrganizationRequireMFA(true);
            }
          }
        });
        self.setFontsPerAccount(result);
      } catch (e) {
        console.log(e);
      }
      self.setLoadStatus("loaded");
      self.loadCallbackFunctions.forEach((func) => func());
    },
  }));
export default accountModel.create({});
export type IaccountModel = Instance<typeof accountModel>;
